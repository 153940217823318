const API_VERSION = "v1"
const CLIENT_SIDE_BASE_URL = `/api/${API_VERSION}`
const SERVER_SIDE_BASE_URL = `${process.env.NEXT_PUBLIC_SERVER_SIDE_API_URL}/${API_VERSION}`

enum REQUEST_TYPE {
  CLIENT = "CLIENT",
  SERVER = "SERVER",
}

export { REQUEST_TYPE, API_VERSION, CLIENT_SIDE_BASE_URL, SERVER_SIDE_BASE_URL }
